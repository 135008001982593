<template>
  <v-container>
    <v-form v-model="formValid" ref="form">
      <v-row justify="center" no-gutters>
        <v-col sm="12" md="8">
          <v-text-field
            v-model="userData.nome"
            label="Seu nome"
            required
            :rules="[v => !!v || 'Insira um nome válido']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-col sm="12" md="8">
          <v-text-field
            v-model="userData.sobrenome"
            label="Seu sobrenome"
            required
            :rules="[v => !!v || 'Insira um sobrenome válido']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-col sm="12" md="8">
          <v-text-field
            v-mask="'###.###.###-##'"
            v-model="userData.cpf"
            label="Seu CPF"
            required
            :rules="[
              v => !!v || 'Insira um CPF válido',
              () => cpfValido || 'Insira um CPF válido'
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      userData: {
        nome: null,
        sobrenome: null,
        cpf: null
      },
      formValid: false,
      invalidCpfs: [
        "00000000000",
        "11111111111",
        "22222222222",
        "33333333333",
        "44444444444",
        "55555555555",
        "66666666666",
        "77777777777",
        "88888888888",
        "99999999999"
      ]
    };
  },
  methods: {
    unmask(mask, value) {
      let tempValue = "";
      mask.split("").forEach((v, index) => {
        if (v === "#" && index < value.length) {
          tempValue += value[index];
        }
      });
      return tempValue;
    }
  },
  watch: {
    formValid(val) {
      if (val) {
        this.$emit("confirmar");
      } else {
        this.$emit("rejeitar");
      }
    }
  },
  mounted() {
    this.$root.$on("reset_fields", () => {
      this.$refs.form.reset();
    });
  },
  computed: {
    unmaskedData() {
      return {
        ...this.userData,
        cpf: this.userData.cpf
          ? this.unmask("###.###.###-##", this.userData.cpf)
          : this.userData.cpf
      };
    },
    cpfValido() {
      let tempCpf = this.userData.cpf;
      if (!tempCpf) return false;
      tempCpf = this.unmask("###.###.###-##", tempCpf);
      if (this.invalidCpfs.includes(tempCpf)) return false;
      let result = 0;
      for (let index = 0; index < 9; index++) {
        result += parseInt(tempCpf[index]) * (10 - index);
      }
      result = ((result * 10) % 11) % 10;
      if (result != parseInt(tempCpf[tempCpf.length - 2])) return false;
      result = 0;
      for (let index = 0; index < 10; index++) {
        result += parseInt(tempCpf[index]) * (11 - index);
      }
      result = ((result * 10) % 11) % 10;
      if (result != parseInt(tempCpf[tempCpf.length - 1])) return false;
      return true;
    }
  }
};
</script>
